<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Upload Record via CSV File</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <v-row class="ma-1">
                <v-col class="d-flex" cols="12" md="2">
                    <v-select label="Table" :items="tables" item-text="apiName" item-value="apiURL" @change="selectTable" data-cy="module-data-table"></v-select>
                </v-col>
            </v-row>
        </v-card>

        <v-container class="container--fluid grid-list-md text-center">
            <h3>File
            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </h3>
        </v-container>
        <v-btn class="mt-2" color="success" @click.stop="submitFile" data-cy="button-submit">Submit</v-btn>



    </div>
</v-app>
</template>

<script>
import API from '@/api/API'
// import SwitchAPI from '@/api/SwitchAPI'
import Loading from '@/views/components/Loading.vue'

export default {
    name: 'fileUpload',
    components: {
        Loading,
    },
    data(){
        return {
            tables: [{
                apiName: "Bill",
                apiURL: `${process.env.VUE_APP_BILLING_SYSTEM_PATH}/api/bill/upload`
            }, {
                apiName: "Account",
                apiURL: `${process.env.VUE_APP_BILLING_SYSTEM_PATH}/api/account/upload`
            }, {
                apiName: "Payment",
                apiURL: `${process.env.VUE_APP_BILLING_SYSTEM_PATH}/api/payment/upload`
            }],
            // tableList: this.tables ?? [],
            selectedTable: {},
            options: {},
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isHtml: false,
            file: ''
        }
    },
    watch: {
        options: {
            handler() {
                this.setData()
            },
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    async mounted() {
        this.setData()
    },
    methods: {
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
            console.log("this file: "+this.file);
        },
        async submitFile(){
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            try {
                this.loadingMessage = `Uploading File <strong><i>${this.file.name}</i></strong>`
                let formData = new FormData();
                formData.append('file', this.file);
                // formData.append('merchantId', this.selectedMerchant)
                const response = await API.uploadFile(this.selectedTable, formData)
                this.loadingFinished = true
                if (!response || response.error) {
                    //error getting data
                    console.log(`${response.error}`)
                    this.isHtml = false
                    this.loadingMessage = response.error
                } else {
                    this.loadingMessage = `Successfully uploaded <strong><i>${this.file.name}</i></strong>`
                    console.log("Upload Successfull")
                }
            } catch (e) {
                console.log(e)
                this.isHtml = false
                this.loadingMessage = e.error
            }
            this.loading = false

        },
        selectTable: async function (val) {
            this.selectedTable = val;
        },
        closeDialog(type) {
            console.log('type:', type);
            if (type === 'loading') {
                this.loadingDialog = false
                this.$router.go(0);
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },

    },
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
